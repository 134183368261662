import React, { useState, useRef, useEffect } from "react";
import "./App.css";

function App() {
  const [isRecording, setIsRecording] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const webcamRef = useRef(null);
  const screenRef = useRef(null);
  const webcamRecorderRef = useRef(null);
  const screenRecorderRef = useRef(null);

  const [username, setUsername] = useState("");
  const [languageCode, setLanguageCode] = useState("");
  const [game, setGame] = useState("");
  const [testName, setTestName] = useState("");

  const [videoDevices, setVideoDevices] = useState([]);
  const [selectedVideoDevice, setSelectedVideoDevice] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setUsername(urlParams.get("username") || "");
    setLanguageCode(urlParams.get("languageCode") || "");
    setGame(urlParams.get("game") || "");
    setTestName(urlParams.get("testName") || "");

    requestPermissions();
    getVideoDevices();
  }, []);

  useEffect(() => {
    if (isRecording) {
      startRecording();
    } else {
      stopRecording();
    }
  }, [isRecording]);

  const requestPermissions = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      await navigator.mediaDevices.getDisplayMedia({ video: true });
    } catch (error) {
      console.error("Error requesting permissions:", error);
    }
  };

  const getVideoDevices = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter(device => device.kind === 'videoinput');
    setVideoDevices(videoDevices);
    if (videoDevices.length > 0) {
      setSelectedVideoDevice(videoDevices[0].deviceId);
    }
  };

  const startRecording = async () => {
    const webcamStream = await navigator.mediaDevices.getUserMedia({
      video: { 
        deviceId: selectedVideoDevice ? { exact: selectedVideoDevice } : undefined,
        width: 1195, 
        height: 672 
      },
      audio: true
    });
    const screenStream = await navigator.mediaDevices.getDisplayMedia({
      video: { width: 1280, height: 720 },
      audio: true
    });

    webcamRef.current.srcObject = webcamStream;
    screenRef.current.srcObject = screenStream;

    webcamRecorderRef.current = new MediaRecorder(webcamStream);
    screenRecorderRef.current = new MediaRecorder(screenStream);

    const webcamChunks = [];
    const screenChunks = [];

    webcamRecorderRef.current.ondataavailable = (e) => webcamChunks.push(e.data);
    screenRecorderRef.current.ondataavailable = (e) => screenChunks.push(e.data);

    webcamRecorderRef.current.onstop = () => uploadVideo(webcamChunks, 'player');
    screenRecorderRef.current.onstop = () => uploadVideo(screenChunks, 'screen');

    webcamRecorderRef.current.start();
    screenRecorderRef.current.start();
  };

  const stopRecording = () => {
    webcamRecorderRef.current?.stop();
    screenRecorderRef.current?.stop();
  };

  const uploadVideo = async (chunks, type) => {
    const blob = new Blob(chunks, { type: 'video/mp4' });
    const formData = new FormData();
    formData.append('video', blob, `${type}.mp4`);
    formData.append('type', type);
    formData.append('jugador', username);
    formData.append('game', game);
    formData.append('testOptionalID', testName);
    formData.append('languageCode', languageCode);

    setIsUploading(true);
    setUploadProgress(0);

    try {
      const response = await fetch('https://beetestedback.civersia.com/upload', {
        method: 'POST',
        body: formData,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        }
      });
      if (response.ok) {
        console.log(`${type} video uploaded successfully`);
      } else {
        console.error(`${type} video upload failed`);
      }
    } catch (error) {
      console.error(`Error uploading ${type} video:`, error);
    } finally {
      setIsUploading(false);
    }
  };
  

  return (
    <div className="App">
      <header className="App-header">
        <img className="logo" src="/logo.png"></img>
      </header>
      <main className="App-main">
        <div className="user-info">
          <p><span>Username:</span> {username}</p>
          <p><span>Language Code:</span> {languageCode}</p>
          <p><span>Game:</span> {game}</p>
          <p><span>Test Name:</span> {testName}</p>
        </div>
        <div className="webcam-selection">
          <label htmlFor="webcam-select">Choose webcam: </label>
          <select 
            id="webcam-select" 
            value={selectedVideoDevice} 
            onChange={(e) => setSelectedVideoDevice(e.target.value)}
          >
            {videoDevices.map((device) => (
              <option key={device.deviceId} value={device.deviceId}>
                {device.label || `Camera ${videoDevices.indexOf(device) + 1}`}
              </option>
            ))}
          </select>
        </div>
        <button 
          className={`record-button ${isRecording ? 'recording' : ''}`} 
          onClick={() => setIsRecording(!isRecording)}
          disabled={isUploading}
        >
          <span className="button-text">{isRecording ? 'Stop Recording' : 'Start Recording'}</span>
          <span className="button-icon">{isRecording ? '⏹' : '⏺'}</span>
        </button>
        {isRecording && (
          <div className="preview-container">
            <div className="webcam-container">
              <video ref={webcamRef} autoPlay muted className="webcam-video" width="1195" height="672" />
            </div>
            <video ref={screenRef} autoPlay muted className="preview-video" width="1280" height="720" />
          </div>
        )}
        {isUploading && (
          <div className="upload-status">
            <p>Please wait while the files are uploading...</p>
            <progress value={uploadProgress} max="100"></progress>
            <p>{uploadProgress}% Uploaded</p>
          </div>
        )}
      </main>
    </div>
  );
}

export default App;
